import { Link, useLocation } from 'react-router-dom';
import './header.scss';

const Header = () => {
    const getLocation = useLocation();
    const {pathname} = getLocation
    console.log(pathname);
    return (
        <div className={`header container-fluid`}>
            <div className="row">
                <div className={`col-6 ${pathname === '/' ? 'homeLogo': 'logo'}`}>
                    <img src='./assets/images/rkrandhir_logo_white.png' alt='logo' />
                </div>
                <div className={`${pathname === '/' ? 'col-12': 'col-6'} topNavWrap`}>
                    <ul className='topNav'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/aboutMe">About Me</Link></li>
                        <li><Link to="/myBlogs">My Blogs</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header;